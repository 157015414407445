import React, { useState, useEffect } from 'react';
import celestialApi from "./celestial.api";

function JmCelestial() {
  const [objects, setObjects] = useState([{}]);

  useEffect(() => {
    async function getCelestialObjects() {
      const celestialObjects = await celestialApi.getCelestialObjects();
      setObjects(celestialObjects);
    }
    getCelestialObjects();
  }, []);

  const renderTime = () => {
    const date = new Date();
    return date.toString();
  };

  const renderObjects = () => {
    if (objects.length) {
      return objects.map((object, index) => {
        const { name, azimuth, altitude } = object;
        return <li key={index}>
          <span>{ name } (az: {azimuth}, alt: {altitude})</span>
        </li>;
      })
    }
    return <li>No objects...</li>
  };

  return (
    <div className="JmCelestial">
      <span>{ renderTime() }</span>
      <ul>{ renderObjects() }</ul>
    </div>
  );
}

export default JmCelestial;
