import celestialMapper from "./celestial.mapper";

const getCelestialObjects = async () => {
  const object = 'moon';
  const timestamp = Date.now().valueOf() / 1000;
  const time = timestamp.toFixed();
  const lat = '52n';
  const lon = '21e';

  console.log(process.env);

  const url = `/observation?objects=${object}&time=${time}&lat=${lat}&lon=${lon}`;
  const response = await fetch(url);
  const rawObjects = await response.json();
  const mappedObjects = celestialMapper.mapCelestialObjects(rawObjects);
  return mappedObjects;
};

const celestialApi = { getCelestialObjects };

export default celestialApi;
